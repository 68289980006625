import { gaCustomDimensions } from './config';

export const getGameDefaults = (config) => {
    const {
        activeAdProviders,
        adsConfig,
        buildVersion,
        buildPath,
        lang,
        domain,
    } = config;

    return {
        ad: { providers: activeAdProviders, ...adsConfig },
        gameConfig: {
            buildVersion: buildVersion,
            buildUrl: `${buildPath}${buildVersion}/`,
            main: {
                Language: lang,
                AddressablesSettingsPath: `${buildPath}${buildVersion}`,
                AddressablesBundlePath: `${domain}/bundle/${buildVersion}/WebGL`,
            },
            dimensions: gaCustomDimensions,
        },
    };
};
