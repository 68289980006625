import { environment, isLocal } from 'App/Helpers';

const env = environment();
let config;

const adsConfig = {
    adProvidersSdkLinks: {
        vli: '//cdn.vlitag.com/w/4f705e57-dced-4080-8ea7-b302538a0381.js',
        adinplay:
            '//api.adinplay.com/libs/aiptag/pub/RYM/bacanagol.com/tag.min.js',
        epom: 'https://cdn77.aj2543.bid/3b133b93.js',
    },
    screenBannerMap: {
        HP: ['center-right_hp', 'footer_hp'],
        GO: ['center_result', 'footer_result'],
        Pause: ['center_pause', 'footer_pause'],
        Gameplay: ['footer_gameplay'],
        BonusChest: ['footer_bonus-chest'],
        SkinOpened: ['footer_skin-opened'],
        Customization: ['footer_customization'],
        RewardProgress: ['footer_reward-progress'],
        DailyChallenge: ['footer_daily-challenge'],
        Offer: ['footer_offer'],
        Settings: null,
        OfferStatus: null,
        LoadingScreen: null,
    },
};

const baseConfig = {
    buildVersion: '1.3.4.0',
    lang: 'pt',
    isStage: true,
    sourceName: 'Bacanagol',
    gameAnalyticsKey: '82028d998441cfbbcd773ef236b10e9c', // link to Game Analytics app - https://tool.gameanalytics.com/game/284703/
    gameAnalyticsSecret: '673cf8464f7a602dd2e022928ff8d6e797c19e08',
    gaTrackId: 'G-7QMGB80HXS', // Google Analytics app name: Games-Dev-ALL; stream name: Games Dev
    sentryEnabled: true,
    modalsUrl: 'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=',
    terms: 'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=termos-de-uso',
    privacy:
        'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=politica-de-privacidade',
    activeAdProviders: ['dev'],
    adsConfig,
};

const local = {
    ...baseConfig,
    rid: 115,
    campaignId: 38,
    source: `${baseConfig.sourceName} Local`,
    sharpstarApiUrl: 'https://dcdev.sharpstar.pro/',
    domain: 'http://127.0.0.1:8080',
    cookiesDomain: '127.0.0.1:8080',
    buildPath: '',
    oneSignalAppId: 'cd43657e-9a63-4013-b24e-fa3486f748b0', // OneSignal local app name: Localhost CY Development
    safariWebId: 'web.onesignal.auto.01b20842-ed7c-48c4-bd42-e78491d78625',
};

const dev = {
    ...baseConfig,
    rid: 160,
    campaignId: 63,
    source: `${baseConfig.sourceName} Dev`,
    sharpstarApiUrl: 'https://dcstage.sharpstar.pro/',
    domain: 'https://dev.bacanagol.com',
    cookiesDomain: 'dev.bacanagol.com',
    buildPath: 'https://dev.bacanagol.com/build/',
    oneSignalAppId: '879f4b42-ffe0-4fc2-8779-35ef106c32ee', // OneSignal stage app name: Development BR Development
    safariWebId: 'web.onesignal.auto.01d22b73-bccb-4a84-9633-69c73285f3f4',
};

const stage = {
    ...baseConfig,
    rid: 165,
    campaignId: 75,
    source: `${baseConfig.sourceName} Stage`,
    sharpstarApiUrl: 'https://dcstage.sharpstar.pro/',
    activeAdProviders: ['epom', 'vli'],
    domain: 'https://stage.bacanagol.com',
    cookiesDomain: 'stage.bacanagol.com',
    buildPath: 'https://stage.bacanagol.com/build/',
    oneSignalAppId: '37b3686f-42eb-4a91-8ee0-30bea189f12d',
    safariWebId: 'web.onesignal.auto.2bb5f943-5f36-4c5b-98f3-fab8c5252044',
};

const prod = {
    ...baseConfig,
    isStage: false,
    rid: 127,
    campaignId: 43,
    source: `${baseConfig.sourceName} Prod`,
    activeAdProviders: ['epom', 'vli'],
    sharpstarApiUrl: 'https://dc.sharpstar.pro/',
    domain: 'https://bacanagol.com',
    cookiesDomain: 'bacanagol.com',
    buildPath: 'https://bacanagol.com/game/',
    oneSignalAppId: '7a89f563-c9a6-42ec-9117-8595e45bde77', // OneSignal prod app name: Games_web BR Production
    safariWebId: 'web.onesignal.auto.37bbdda8-1be5-416a-8d2a-3d51b0669a43',
    gaTrackId: 'G-EYRMRLHD2R', // Google Analytics app name: Football-BR
    gameAnalyticsKey: 'a54d03eeebbf057b8b486126e7e395a5', // link to Game Analytics app - https://tool.gameanalytics.com/game/266513/
    gameAnalyticsSecret: '98131f4980849c8fa568756d53984384d0d5565a',
};

if (env === 'production') {
    config = prod;
} else if (env === 'development') {
    config = dev;
} else {
    config = local;
}
export const languages = ['pt', 'en', 'es', 'ro', 'bg', 'gr'];

export const oneSignalConfig = {
    appId: config.oneSignalAppId,
    safari_web_id: config.safariWebId,
    serviceWorkerPath: isLocal()
        ? 'OneSignalSDKWorker.js'
        : 'push/OneSignalSDKWorker.js',
    serviceWorkerParam: {
        scope: isLocal() ? '/' : '/push/',
    },
    notifyButton: {
        enable: false,
    },
    welcomeNotification: {
        disable: true,
    },
    allowLocalhostAsSecureOrigin: isLocal(),
    autoRegister: false,
};

export const gaCustomDimensions = {
    values1: ['push_default', 'push_granted', 'push_denied'],
    values2: ['pwa_installed', 'pwa_not_installed', 'pwa_not_supported'],
    values3: [
        'Low_RAM',
        'Medium_Low_RAM',
        'Medium_RAM',
        'High_RAM',
        'Very_High_RAM',
        'Ultra_High_RAM',
        'Unknown_RAM',
    ],
};

export default config;
