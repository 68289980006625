import { AD_UPDATE_CHECK_DELAY_MS } from './constants';

/**
 * Check if container DOM element is iframe element or it has iframe element
 *
 * @param element - <HTMLElement>(required)
 * @param tagName - string(optional)
 *
 * @return boolean
 */
export const hasAdElement = (element, tagName) => {
    if (element.tagName === tagName) {
        // If the element is the same as tagName, return true
        return true;
    } else {
        // Otherwise, check each child element recursively
        for (let i = 0; i < element.childElementCount; i++) {
            if (hasAdElement(element.children[i], tagName)) {
                // If any child element has the same element as tagName, return true
                return true;
            }
        }
    }
    // If no element the same as tagName is found, return false
    return false;
};

export const checkAdContainerAndSendAnalytics = (
    bannerElement,
    gaEventName,
    gameGA,
    ga,
    tagName,
    delay = AD_UPDATE_CHECK_DELAY_MS
) => {
    setTimeout(() => {
        const hasAd = hasAdElement(bannerElement, tagName);

        if (hasAd) {
            gameGA.sendDesignEvent(`Ad:${gaEventName}`);
            ga.basicGaEvent('ad', `${gaEventName}`);
        } else {
            gameGA.sendErrorEvent(
                'Warning',
                `Banner update failed: ${gaEventName}`
            );
            ga.basicGaEvent('error', `fail_${gaEventName}`);
        }
    }, delay);
};

export function getSideBannerConfigurations(isDevMode, inputObject) {
    const getBannerValues = (type) => {
        if (isDevMode) {
            return {
                leftProvider: 'dev',
                rightProvider: 'dev',
                leftId: null,
                rightId: null,
            };
        } else {
            const result = {};

            Object.entries(inputObject).forEach(([key, value]) => {
                const [side, bannerType] = key.split('-');
                const [adProvider, bannerId] = value.split(':');

                if (bannerType === type) {
                    if (side === 'left') {
                        result['leftProvider'] = adProvider;
                        result['leftId'] = bannerId;
                    }
                    if (side === 'right') {
                        result['rightProvider'] = adProvider;
                        result['rightId'] = bannerId;
                    }
                }
            });

            return result;
        }
    };

    const wideBannerValues = getBannerValues('wide');
    const narrowBannerValues = getBannerValues('narrow');

    return { wideBannerValues, narrowBannerValues };
}

export async function fetchEpomBanner(fetchUrl, hash, bannerId) {
    const response = await fetch(
        `${fetchUrl}?key=${bannerId}&cp.hash=${hash || 0}`
    );
    const adScript = await response.text();
    const bannerElement = document.querySelector(`ins[data-key="${bannerId}"]`);

    if (bannerElement) {
        bannerElement.innerHTML = adScript; // Insert the HTML content into the <ins> element

        const scripts = bannerElement.querySelectorAll('script');

        scripts.forEach((script) => {
            const newScript = document.createElement('script');

            if (script.src) {
                newScript.src = script.src;
            } else {
                newScript.textContent = script.textContent;
            }

            document.body.appendChild(newScript);
            document.body.removeChild(newScript);
        });
    }
}

export const updateEpomAd = (ref, leadHash, callAd) => {
    if (ref.current?.getAttribute('data-ga-id')?.includes('epom')) {
        callAd(ref.current, leadHash, false);
    }
};

// export const getAdConfig = (inputObject, prefixToRemove) => {
//     const filteredObject = {};
//
//     Object.entries(inputObject).forEach(([key, value]) => {
//         if (key.startsWith(prefixToRemove)) {
//             const newKey = key.startsWith(prefixToRemove)
//                 ? key.replace(prefixToRemove, '')
//                 : key;
//             filteredObject[newKey] = value;
//         }
//     });
//
//     return filteredObject;
// };

export const getAdConfig = (inputObject) => {
    const prefixes = {
        'ad:banner:': 'banners',
        'ad:side:': 'sideBanners',
        'ad:reward:': 'rewards',
    };

    const adConfig = {
        banners: {},
        sideBanners: {},
        rewards: {},
    };

    Object.entries(inputObject).forEach(([key, value]) => {
        for (const prefix in prefixes) {
            if (key.startsWith(prefix)) {
                const newKey = key.replace(prefix, '');
                adConfig[prefixes[prefix]][newKey] = value;
                break;
            }
        }
    });

    return adConfig;
};
